import React from "react";
import styled from "styled-components";
import Layout from "../../components/Layout/Layout";
import { mediaQuery } from "../../components/_breakpoints";
import { colorsGlobal } from "../../components/_global";
import { graphql } from "gatsby";
import SocialLink from "../../components/ActionButtons/SocialLink";
import facebookIcon from "../../img/social/facebook.svg";
import instagramIcon from "../../img/social/instagram.svg";
import twitterIcon from "../../img/social/twitter.svg";
import * as _ from 'lodash';

const socialMediaIcons = {
  facebook: facebookIcon,
  instagram: instagramIcon,
  twitter: twitterIcon,
};

const PersonCard = ({
  imie,
  twitter,
  instagram,
  otherLinks,
  opis,
  imageSrc,
  facebook,
    bolded
}) => {
  const resolveLink = (link) =>
    link.includes("../static") ? link.split("../static")[1] : link;

  return (
    <PersonCardWrapper bolded={bolded}>
      <PersonImg src={resolveLink(imageSrc)} />
      <PersonName>{imie}</PersonName>
      <PersonDescription>{opis}</PersonDescription>
      <LinksSection>
        <LinksWrapper>
          <SocialMediaLinksWrapper>
            {twitter && (
              <SocialLinkStyled href={twitter}>
                <SocialMediaIcon src={socialMediaIcons.twitter} />
              </SocialLinkStyled>
            )}
            {instagram && (
              <SocialLinkStyled href={instagram}>
                <SocialMediaIcon src={socialMediaIcons.instagram} />
              </SocialLinkStyled>
            )}
            {facebook && (
              <SocialLinkStyled href={facebook}>
                <SocialMediaIcon src={socialMediaIcons.facebook} />
              </SocialLinkStyled>
            )}
          </SocialMediaLinksWrapper>
          {otherLinks && (
            <>
              {otherLinks.map((el) => {
                return (
                    <OtherLinks>
                      <SocialLink href={el.link} name={el.linkName} />
                    </OtherLinks>
                )
              })}
            </>
          )}
        </LinksWrapper>
      </LinksSection>
    </PersonCardWrapper>
  );
};


export default (props) => {

  const people = _.get(props, 'data.markdownRemark.frontmatter.people', []);
  const slicedPeople = people.slice(1);

  return (
    <Layout>
      {props.data &&
        props.data.markdownRemark &&
        props.data.markdownRemark.frontmatter && (
          <AboutUsWrapper>
            <AboutUsText>
              {props.data.markdownRemark.frontmatter.description}:
            </AboutUsText>
            <PersonCard
                imie={people[0].name}
                imageSrc={people[0].avatar}
                instagram={people[0].instagram}
                twitter={people[0].twitter}
                facebook={people[0].facebook}
                otherLinks={people[0].otherLinks}
                opis={people[0].description}
                bolded
            />
            <CardsWrapper>
              {slicedPeople.map((el) => (
                <PersonCard
                  imie={el.name}
                  imageSrc={el.avatar}
                  instagram={el.instagram}
                  twitter={el.twitter}
                  facebook={el.facebook}
                  otherLinks={el.otherLinks}
                  opis={el.description}
                />
              ))}
            </CardsWrapper>
          </AboutUsWrapper>
        )}
    </Layout>
  );
};

export const aboutUsPageQuery = graphql`
  query AboutUsPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "about-us-page" } }) {
      frontmatter {
        templateKey
        description
        people {
          name
          avatar
          description
          facebook
          instagram
          twitter
          otherLinks {
            linkName
            link
          }
        }
      }
    }
  }
`;

const AboutUsText = styled.h3`
  font-size: 20px;
  padding-left: 10px;
  display: flex;
`;

const SocialMediaIcon = styled.img`
  display: flex;
  width: 30px;
  height: 30px;
  ${mediaQuery.sm(`
    width: 20px;
    height: 20px;
  `)}
`;

const SocialMediaLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 10px;
  top: 10px;
`;

const LinksWrapper = styled.div`
  display: flex;
  margin-top: 5px;
`;

const LinksSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 80%;
`;

const SocialLinkStyled = styled.a.attrs({ target: "_blank" })`
  margin: 6px 5px;
  ${mediaQuery.sm(`
    margin: 3px 5px;
  `)}
`;

const CardsWrapper = styled.div`
  display: flex;
  height: fit-content;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-wrap: wrap;
  ${mediaQuery.xl(`
    justify-content: flex-start;
  `)}
`;

const AboutUsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 100px;
  width: 100%;
  ${mediaQuery.sm(`
      margin-left: 100px;
  `)}
`;

const PersonCardWrapper = styled.div`
  display: flex;
  position: relative;
  background: ${props => props.bolded ? '#fffdf0' : '#f7f0ff'};
  border-radius: 10px;
  border: solid ${props => props.bolded ? '4px' : '2px'} ${props => props.bolded ? colorsGlobal.secondary : colorsGlobal.primary};
  padding: 20px 0 10px;
  margin: 10px auto;
  flex-direction: column;
  align-items: center;
  width: 300px;
  justify-content: center;
  z-index: 50;
  ${mediaQuery.sm(`
    margin: 10px;
  `)}
`;

const PersonImg = styled.img`
  display: flex;
  vertical-align: middle;
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

const PersonName = styled.div`
  display: flex;
  margin-top: 5px;
  font-size: 24px;
  justify-content: center;
  width: 80%;
`;

const PersonDescription = styled.div`
  display: flex;
  margin-top: 15px;
  width: 80%;
  text-align: justify;
`;

const Twitter = styled.div`
  display: flex;
  width: 80%;
`;

const Instagram = styled.div`
  display: flex;
  width: 80%;
`;

const OtherLinks = styled.div`
  display: flex;
  width: 80%;
`;
